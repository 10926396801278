<template>
  <Layout>
    <div class="bsat__container my-sm-10">
      <a
        @click="$router.go(-1)"
        class="bsat__btn dark-text px-0 font-weight-bold"
        style="font-size: 16px !important"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("backToPhase3") }}
      </a>

      <div class="light_green-text bsat__title font-weight-bold my-4 pt-sm-9">
        {{ $t("title") }}
      </div>

      <!-- <section>
        <div class="bsat__subtitle mb-2">{{ $t("section1.title") }}</div>
        <div class="bsat__paragraph">{{ $t("section1.paragraph") }}</div>
      </section> -->

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section2.title") }}</div>
        <a :href="financialToolsFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section3.title") }}</div>
        <a :href="financialInstumentsFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section4.title") }}</div>
        <a :href="euStructuralFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <div class="bsat__subtitle mb-2">{{ $t("section5.title") }}</div>
        <div class="bsat__paragraph">{{ $t("section5.paragraph") }}</div>
        <a :href="questionnaireFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <!--*********************** SECTION 6 ************************* -->
        <div class="bsat__subtitle mb-2">{{ $t("section6.title") }}</div>

        <ReadMore>
          <v-data-table
            class="border"
            :headers="headers"
            :items="table"
            hide-default-footer
          >
            <template #item.cases="{ item }">
              <ul>
                <li
                  v-for="(li, i) in item.cases"
                  :key="i"
                  v-html="li.title"
                ></li>
              </ul>
            </template>

            <template #item.download="{ item }">
              <a :href="item.download" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn mt-2"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </template>
          </v-data-table>

          <!--           
          <div class="bsat__paragraph mt-8 mb-4 font-weight-bold">
            {{ $t("section6.equity") }}
          </div>

          <v-data-table
            class="border"
            :headers="headers"
            :items="equityExamples"
            hide-default-footer
          >
            <template #item.title="{item}">
              <div v-html="item.title"></div>
            </template>
          </v-data-table> -->
          <!-- <ol>
            <li
              class="bsat__paragraph mb-2"
              v-for="(item, i) in equityExamples"
              :key="i"
            >
              <div v-if="item.link">
                <a :href="item.link" target="_blank">{{ item.linkTitle }}</a
                >{{ item.title }}
              </div>
              <div v-else>
                {{ item.title }}
              </div>
            </li>
          </ol> -->

          <!-- <a :href="equityFile" target="_blank" download>
            <v-btn
              depressed
              width="150"
              height="26"
              class="bsat__btn mt-2"
              dark
              color="dark"
              >{{ $t("download") }}</v-btn
            >
          </a>

          <div class="bsat__paragraph mb-4 font-weight-bold">
            {{ $t("section6.quasiEquity") }}
          </div>
          <v-data-table
            class="border"
            :headers="headers"
            :items="quasiEquityExample"
            hide-default-footer
          ></v-data-table> -->
          <!-- <ol>
            <li class="bsat__paragraph mb-2">{{ quasiEquityExample }}</li>
          </ol> -->
          <!-- <a :href="quasiEquityFile" target="_blank" download>
            <v-btn
              depressed
              width="150"
              height="26"
              class="bsat__btn mt-2"
              dark
              color="dark"
              >{{ $t("download") }}</v-btn
            >
          </a>

          <div class="bsat__paragraph mb-4 font-weight-bold">
            {{ $t("section6.loans") }}
          </div>

          <v-data-table
            class="border"
            :headers="headers"
            :items="loansExamples"
            hide-default-footer
          ></v-data-table> -->
          <!-- <ol>
            <li
              class="bsat__paragraph mb-2"
              v-for="(item, i) in loansExamples"
              :key="i"
              v-text="item"
            ></li>
          </ol> -->
          <!-- <a :href="loansFile" target="_blank" download>
            <v-btn
              depressed
              width="150"
              height="26"
              class="bsat__btn mt-2"
              dark
              color="dark"
              >{{ $t("download") }}</v-btn
            >
          </a>

          <div class="bsat__paragraph mb-4 font-weight-bold">
            {{ $t("section6.guarantees") }}
          </div>
          <v-data-table
            class="border"
            :headers="headers"
            :items="guaranteesExamples"
            hide-default-footer
          ></v-data-table> -->
          <!-- <ol>
            <li
              class="bsat__paragraph mb-2"
              v-for="(item, i) in guaranteesExamples"
              :key="i"
              v-text="item"
            ></li>
          </ol> -->
          <!-- <a :href="guaranteesFile" target="_blank" download>
            <v-btn
              depressed
              width="150"
              height="26"
              class="bsat__btn mt-2"
              dark
              color="dark"
              >{{ $t("download") }}</v-btn
            >
          </a>

          <div class="bsat__paragraph mb-4 font-weight-bold">
            {{ $t("section6.grants") }}
          </div>
          <v-data-table
            class="border"
            :headers="headers"
            :items="grantsExamples"
            hide-default-footer
          ></v-data-table> -->
          <!-- <ol>
            <li
              class="bsat__paragraph mb-2"
              v-for="(item, i) in grantsExamples"
              :key="i"
              v-text="item"
            ></li>
          </ol> -->
          <!-- <a :href="grantsFile" target="_blank" download>
            <v-btn
              depressed
              width="150"
              height="26"
              class="bsat__btn mt-2"
              dark
              color="dark"
              >{{ $t("download") }}</v-btn
            >
          </a>

          <div class="bsat__paragraph mb-4 font-weight-bold">
            {{ $t("section6.blendedFinance") }}
          </div>

          <v-data-table
            class="border"
            :headers="headers"
            :items="blendedFinanceExamples"
            hide-default-footer
          ></v-data-table> -->

          <!-- <ol>
            <li
              class="bsat__paragraph mb-2"
              v-for="(item, i) in blendedFinanceExamples"
              :key="i"
              v-text="item"
            ></li>
          </ol> -->
          <!-- <a :href="blendedFinanceFile" target="_blank" download>
            <v-btn
              depressed
              width="150"
              height="26"
              class="bsat__btn mt-2"
              dark
              color="dark"
              >{{ $t("download") }}</v-btn
            >
          </a> -->
        </ReadMore>
      </section>

      <section>
        <!-- *********************** SECTION 7 ************************* -->

        <div class="bsat__subtitle mb-2">{{ $t("section7.title") }}</div>
        <ReadMore>
          <ol>
            <li
              class="bsat__paragraph mb-3"
              v-for="(item, i) in section7OrderedList"
              :key="i"
            >
              <v-card flat class="border">
                <v-card-text>{{ item }}</v-card-text>
              </v-card>
            </li>
          </ol>
        </ReadMore>
      </section>

      <section>
        <!-- ****************************** SECTION 8 ************************* -->
        <div class="bsat__subtitle mt-8">{{ $t("section8.title") }}</div>
        <a :href="synergiesFundingFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>

      <section>
        <!-- ****************************** SECTION 9 ************************* -->
        <div class="bsat__subtitle">{{ $t("section9.title") }}</div>
        <a :href="recommendationsFile" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn mt-2"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </section>
    </div>
  </Layout>
</template>

<i18n>
{
	"en": {
		"backToPhase3": "Back",
		"readLess": "Read less",
		"readMore": "Read more",
		"section2": {
			"title": "1. Financial tools overview of interest to Bioeconomy"
		},
		"section3": {
			"title": "2. Financial Instruments: General concepts"
		},
		"section4": {
			"title": "3. European Structural and Investment Funds"
		},
		"section5": {
			"paragraph": "Create your good financial schemes in your region by using this template",
			"title": "4. Questionnaire on Financial Instruments"
		},
		"section6": {
			"blendedFinance": "Blended Finance",
			"equity": "Equity",
			"grants": "Grants",
			"guarantees": "Guarantees",
			"loans": "Loans",
			"paragraph": "Select a type of financial instrument and consult the examples available",
			"quasiEquity": "Quasi-Equity ",
			"title": "5. Examples of financial instruments from EU regions and countries. "
		},
		"section7": {
			"orderedList": [
				"Be aware of differences between other sources of funding and ESIF when preparing your regional strategy to stimulate the bio-based economy; ",
				"Draws attention to potential synergies with the new EIB/EIF finance instruments. Access to these instruments for regional beneficiaries can be fostered and supported by regional policies: e.g. using either ESIF or other regional or national public funds to meet requirements in EIB’s risk and due diligence assessments; ",
				"Use different ESIF and finance instruments for measures to stimulate regional innovation systems for the bio-based economy, including the integration of rural development as well as measures for larger scale investments required for industries to move from research to market and enable deployment of the bio-based economy. Better communication of good practices and project results might be necessary. ",
				"Initiatives to coordinate and bring together funds and stakeholders have to be strengthened and further developed in order to enhance visibility and facilitate participation of regions and smaller countries.",
				"Consider with a larger extent innovative companies as final beneficiaries and design suitable financial products to support their growth for large scale (non-bankable) investments in the bioeconomy (e.g. Flagships) finance may be more important to stimulate further investments and development of the bioeconomy at regional and European level, than funding. New European Financial instruments (EIB, EIF) possibly in combination with ESIF (e.g. guarantee funds) may offer such opportunities.",
				"Advises to design a clear strategy and planning for synergetic funding may be asked."
			],
			"title": "6. Tips for Regions including ESIF policymakers and management bodies: "
		},
		"section8": {
			"title": "7. Synergies between different funding programmes"
		},
		"section9": {
			"title": "8. Brief list of Recommendations to create financial schemes for bioeconomy"
		},
    "title": "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy",
    "tableHeaders": {
      "category": "Category",
      "cases": "Cases",
      "download": "Download"
    }
	}
}
</i18n>

<script>
import Layout from "@/layouts/default";
import ReadMore from "@/components/ReadMore";
import { examples } from "../../utils/examplesOfFinancialInstruments";
export default {
  name: "FinancingTools",
  metaInfo: {
    title:
      "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy"
  },
  components: {
    Layout,
    ReadMore
  },
  data() {
    return {
      financialToolsFile: require("@/assets/files/financialTools/FP1_F_UNDING_INSTRUMENTS_FOR_INNOVATIVE_BIO_BASED_SOLUTIONS.pdf")
        .default,
      financialInstumentsFile: require("@/assets/files/financialTools/FP2_FINANCIAL_I_NSTRUMENTS_GENERAL_CONCEPTS.pdf")
        .default,
      euStructuralFile: require("@/assets/files/financialTools/FP3_European_Structural_and_Investment_Funds.pdf")
        .default,
      questionnaireFile: require("@/assets/files/financialTools/FP4_Create_your_good_financial_schemes_in_your_region.docx")
        .default,
      equityFile: require("@/assets/files/financialTools/FP5_Financial_Instruments_Equity.pdf")
        .default,
      quasiEquityFile: require("@/assets/files/financialTools/FP6_Financial_Instruments_Quasi_Equity.pdf")
        .default,
      loansFile: require("@/assets/files/financialTools/FP7_Financial_Instruments_Loans.docx")
        .default,
      guaranteesFile: require("@/assets/files/financialTools/FP8_1_Financial_Instruments_Guarantees.docx")
        .default,
      grantsFile: require("@/assets/files/financialTools/FP9_Grants.docx")
        .default,
      blendedFinanceFile: require("@/assets/files/financialTools/FP10_Blended_Finance.docx")
        .default,
      synergiesFundingFile: require("@/assets/files/financialTools/FP11_Synergies_between_different_funding_programmes.docx")
        .default,
      recommendationsFile: require("@/assets/files/financialTools/FP12_General_Recommendations.docx")
        .default,
      tab: null,
      items: [
        { tab: "about" },
        { tab: "phase1" },
        { tab: "phase2" },
        { tab: "phase3" },
        { tab: "phase4" }
      ]
    };
  },
  computed: {
    section7OrderedList() {
      return Object.values(this.$t("section7.orderedList"));
    },
    headers() {
      return [
        {
          text: this.$t("tableHeaders.category"),
          value: "category",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        },
        {
          text: this.$t("tableHeaders.cases"),
          value: "cases",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        },
        {
          text: this.$t("tableHeaders.download"),
          value: "download",
          align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
          sortable: false,
          groupable: false
        }
      ];
    },
    table() {
      return [
        {
          category: this.$t("section6.equity"),
          cases: examples.equityExamples,
          download: require("@/assets/files/financialTools/FP5_Financial_Instruments_Equity.pdf")
            .default
        },
        {
          category: this.$t("section6.quasiEquity"),
          cases: examples.quasiEquityExample,
          download: require("@/assets/files/financialTools/FP6_Financial_Instruments_Quasi_Equity.pdf")
            .default
        },
        {
          category: this.$t("section6.loans"),
          cases: examples.loansExamples,
          download: require("@/assets/files/financialTools/FP7_Financial_Instruments_Loans.docx")
            .default
        },
        {
          category: this.$t("section6.guarantees"),
          cases: examples.guaranteesExamples,
          download: require("@/assets/files/financialTools/FP8_1_Financial_Instruments_Guarantees.docx")
            .default
        },
        {
          category: this.$t("section6.grants"),
          cases: examples.grantsExamples,
          download: require("@/assets/files/financialTools/FP9_Grants.docx")
            .default
        },
        {
          category: this.$t("section6.blendedFinance"),
          cases: examples.blendedFinanceExamples,
          download: require("@/assets/files/financialTools/FP10_Blended_Finance.docx")
            .default
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";
.border {
  border: 1px solid $light_green;
}
a {
  padding-bottom: 20px;
}
.bsat__tabs {
  .v-tab {
    font-family: $font-family;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    color: $inactive_grey !important;
  }

  .v-tab .v-tab--active {
    color: $inactive_grey;
    background: none;
  }
}
</style>
