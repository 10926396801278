export const examples = {
  equityExamples: [
    {
      title: "<a href='http://aglaunch.com/'>AgLaunch + Innova Memphis</a>"
    },
    { title: "Ag-West Bio’s Technology Commercialization Investment Fund" },
    {
      title:
        "<a href='https://www.crunchbase.com/fund/anterra-capital-raised-anterra-capital-investment-fund--ef40e3cb'>Anterra Capital Investment Fund</a>"
    },
    { title: "CAPAGRO" },
    { title: "ECBF" },
    { title: "Ingenium Emilia Romagna" },
    { title: "INNOVA Venture" },
    { title: "INNVIERTE" },
    { title: "Lazio Innova" },
    { title: "Rural Business Investment Program" },
    { title: "Société des projets industriels (SPI) investment programme" },
    { title: "Syngenta Ventures" },
    { title: "Technology Innovation Guiding Fund" }
  ],
  quasiEquityExample: [{ title: "Hauts de France CAP 3RI" }],
  loansExamples: [
    { title: "Agriculture and Bioeconomy Programme Loan" },
    { title: "Biotechnology Commercialisation Fund (BCF 2.0)	" },
    { title: "Food and agricultural loan fund 2014-2020 (ErLA)" },
    { title: "Innovation Demonstration Fund (IDF)" },
    { title: "Ressourceneffizienzfinanzierung" },
    { title: "The Romania EAFRD Fund of Funds" },
    { title: "The Silesia EIF Fund of Funds" }
  ],
  guaranteesExamples: [
    { title: "AGRI Italy Platform Uncapped Guarantee Instrument" },
    { title: "ESIF Energy Efficiency and Renewable Energy Malta" }
  ],
  grantsExamples: [
    { title: "Agricultural Research Trajectories" },
    { title: "Biotechnology Ignition Grant Scheme (BIG)" },
    { title: "Biotechnology Industry Partnership Programme (BIPP)" },
    { title: "BMBF - Bioeconomy international" },
    {
      title:
        'Bayerisches Förderprogramm "Technologieorientierte Unternehmensgründungen" (BayTOU)'
    },
    { title: "Bayerisches Technologieförderungs-Programm plus (BayTP+)" },
    { title: "Eastern Agri-Tech Growth Initiative" },
    {
      title:
        "European Innovation Partnerships (EIP) – Agricultural Productivity and Sustainability"
    },
    { title: "Innovation Mandates" },
    { title: "Investments in Forest Industry Transformation (IFIT)" },
    { title: "Neue Produckte  für die BioÖkonomie" },
    { title: "Piattaforma Tecnologica Bioeconomia" },
    { title: "Poli d’Innovazione" },
    { title: "PAISS Industry Program" },
    {
      title:
        "Program for the Promotion of International R + D + i (Programa de Fomento de la I+D+i Internacional)"
    }
  ],
  blendedFinanceExamples: [
    { title: "BOM Venture Capital fund" },
    { title: "Enterprise Capital Fund (ECF)" },
    { title: "Innovation Fund in East Netherlands" },
    { title: "SC-UP – Suppor to alle start up innovative" },
    { title: "Support of R&D and Promotion of Technological Innovation" }
  ]
};
